import React from 'react';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { Row, Col,Popover} from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';

import image1 from '../Images/Home/datu.png';
import image2 from '../Images/Home/11.png';
import image3 from '../Images/Home/yingwen.png';
import image4 from '../Images/LAB/anniu4_hui.png';
import image5 from '../Images/LAB/anniu5_hui.png';

import image11 from '../Images/LAB/anniu1.png';
import image22 from '../Images/LAB/anniu2.png';
import image33 from '../Images/LAB/anniu3.png';
import image44 from '../Images/LAB/anniu4.png';
import image55 from '../Images/LAB/anniu5.png';

import imagepath from '../Images/LAB/The path.png'
import imgLast from '../Images/Home/icon.png'
function Content1(props) {
  const { ...tagProps } = props;
  const {isMobile } = tagProps;
  delete tagProps.isMobile;
  const animType = {
    queue: isMobile ? 'bottom' : 'right',
    one: isMobile
      ? {
          scaleY: '+=0.3',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        }
      : {
          x: '-=30',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        },
  };


  function imageOnMouseLeave (e) {
    switch (e.target.id)
    {
      case "img_1":
        e.target.src = image1
        break;
      case "img_2":
        e.target.src = image2
        break;
      case "img_3":
        e.target.src = image3
        break;
      case "img_4":
        e.target.src = image4
        break;
      case "img_5":
        e.target.src = image5
        break;
      default:
          break;
    }
  };

  return (
    <div className="home-page-wrapper content6-wrapper">
      <OverPack className="home-page content6" playScale={0.3} component={Row}>
        <TweenOne
          key="img"
          animation={animType.one}
          resetStyle
          className="content6-img1" md={17} xs={24}
          component={Col}
          componentProps={{
            md: 17,
            xs: 24,
          }}
        >
                  <div className="span">
              <img src={image2} id='img_1' alt="img" />
          </div>
          <div className="span5" >
              <img src={image1} id='img_1' alt="img" />
          </div>

        
        </TweenOne>
        <QueueAnim
          key="text"
          type={animType.queue}
          leaveReverse
          ease={['easeOutQuad', 'easeInQuad']}
          className="content6-text"
          component={Col}
          componentProps={{
            md: 6,
            xs: 24,
          }}
        >
          
        </QueueAnim>
      </OverPack>
    </div>
  );
}

export default Content1;
