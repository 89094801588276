import React from 'react';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import {Button, Row, Col,Popover} from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { Link } from 'react-router-dom';
import image1 from '../Images/lianxi/ditu.png';
import image2 from '../Images/lianxi/erweima.png';
import imgLast from '../Images/lianxi/icon.png'
function Content1(props) {
  const { ...tagProps } = props;
  const { dataSource, isMobile } = tagProps;
  delete tagProps.dataSource;
  delete tagProps.isMobile;
  const animType = {
    queue: isMobile ? 'bottom' : 'right',
    one: isMobile
      ? {
          scaleY: '+=0.3',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        }
      : {
          x: '-=30',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        },
  };

 

  return (
    <div {...tagProps} {...dataSource.wrapper}>
      <OverPack {...dataSource.OverPack} component={Row}>
        <TweenOne
          key="img"
          animation={animType.one}
          resetStyle
          {...dataSource.imgWrapper}
          component={Col}
          componentProps={{
            md: dataSource.imgWrapper.md,
            xs: dataSource.imgWrapper.xs,
          }}
        >
          <span {...dataSource.img}>
              <img src={image1} id='img_1' alt="img" />
          </span>
        </TweenOne>
        <QueueAnim
          key="text"
          type={animType.queue}
          leaveReverse
          ease={['easeOutQuad', 'easeInQuad']}
          {...dataSource.textWrapper}
          component={Col}
          componentProps={{
            md: dataSource.textWrapper.md,
            xs: dataSource.textWrapper.xs,
          }}
        >
          
          <h2 key="h1" {...dataSource.title}>
            <img className="ImageIcon" src={imgLast} ></img>
            {dataSource.title.children}
          </h2>
          <div  {...dataSource.content}>
           地 址：黑龙江省哈尔滨市道外区东直路177号金泰创意 
          </div>
          <div  {...dataSource.content}>
           服务热线：4000-9393-99
          </div>
          <div  {...dataSource.content}>
  联系电话：0451-55568849
          </div>
          <div  {...dataSource.content}>
  email：xy@kingtime.art
          </div>
          <img src={image2} style={{position:"relative",top:10,marginBottom:20,fontSize:5}}></img>
        </QueueAnim>
      </OverPack>
    </div>
  );
}

export default Content1;
