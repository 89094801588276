import React from 'react';
import image1 from '../Images/know/1.png'
import image2 from '../Images/know/2.png'
import image3 from '../Images/know/3.png'
import image4 from '../Images/know/4.png'
export const Teams00DataSource = {
  wrapper: { className: 'home-page-wrapper teams0-wrapper' },
  OverPack: { playScale: 0.3, className: 'home-page teams0' },

  BannerAnim: {
    className: 'banner-anim',
    children: [
      {
        name: 'elem0',
        className: 'teams0-banner-user-elem',
        titleWrapper: {
          className: 'teams0-content-wrapper',
          image:image1,
          title1:'上海凤凰文化博物馆项目',
          title2:'(项目时间：2019-03-01—2019-06-30)',
          title3:'“科技是第一生产力”，广西科技馆作为公众提高科学文化素质，了解社会科技发展，了解广西科技特征，感受科技魅力的文化设施，发挥着提高公众科技应用能力，激发公众探索精神和创新动力的重要作用，更是中国（广西）与东盟各国开展科技文化交流的重要平台。 我们将综合利用艺术化、智能化、现代化的手段对广西科技馆这个科技知识教育的窗口进行深层次、全方位、多角度、立体化的集中展示，为建设创新型广西和构建和谐广西服务。',
        },
        
      },
      {
        name: 'elem1',
        className: 'teams0-banner-user-elem',
        titleWrapper: {
          className: 'teams0-content-wrapper',
          image:image2,
        },
        
      },
      {
        name: 'elem1',
        className: 'teams0-banner-user-elem',
        titleWrapper: {
          className: 'teams0-content-wrapper',
          image:image3,
        },
        
      },
      {
        name: 'elem1',
        className: 'teams0-banner-user-elem',
        titleWrapper: {
          className: 'teams0-content-wrapper',
          image:image4,
        },
        
      },
    ],
  },
};
