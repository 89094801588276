import React from 'react';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { Row, Col,Popover,Button} from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';

import image1 from '../Images/art/1.png';
import image2 from '../Images/art/2.png';
import image3 from '../Images/art/3.png';
import image4 from '../Images/art/4.png';
import image5 from '../Images/art/5.jpg';
import { Link } from 'react-router-dom';
function Content1(props) {
  const { ...tagProps } = props;
  const { dataSource, isMobile } = tagProps;
  delete tagProps.dataSource;
  delete tagProps.isMobile;
  const animType = {
    queue: isMobile ? 'bottom' : 'right',
    one: isMobile
      ? {
          scaleY: '+=0.3',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        }
      : {
          x: '-=30',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        },
  };
  return (
    <div {...tagProps} {...dataSource.wrapper}>
      <OverPack {...dataSource.OverPack} component={Row}>
        <TweenOne
          key="img"
          animation={animType.one}
          resetStyle
          {...dataSource.imgWrapper}
          component={Col}
          componentProps={{
            md: dataSource.imgWrapper.md,
            xs: dataSource.imgWrapper.xs,
          }}
        >
          <div className="span4" {...dataSource.img}>
              <img src={image5} id='img_1' alt="img" />
          </div>
          <div className="span" {...dataSource.img}>
              <img src={image1} id='img_1' alt="img" />
          </div>
          <div className="span1" {...dataSource.img}>
              <img src={image2} id='img_1' alt="img" />
          </div>
          <div className="span2" {...dataSource.img}>
              <img src={image3} id='img_1' alt="img" />
          </div>
          <div className="span3" {...dataSource.img}>
              <img src={image4} id='img_1' alt="img" />
          </div>
        </TweenOne>
        <QueueAnim
          key="text"
          type={animType.queue}
          leaveReverse
          ease={['easeOutQuad', 'easeInQuad']}
          {...dataSource.textWrapper}
          component={Col}
          componentProps={{
            md: dataSource.textWrapper.md,
            xs: dataSource.textWrapper.xs,
          }}
        >
          
          <h2 key="h1" {...dataSource.title}>

        金泰人本着“认真打造每一个项目，热心服务每一位客户”的宗旨，紧紧围绕客户的需求，全力服务客户，“想客户之所想，急客户之所急”。更要站在专业的角度，帮助客户理性分析项目执行中遇到的各种问题，帮助客户“扫雷”，为项目保驾护航。用金泰人的所学、所知全力辅助客户顺利、高效地完成任务。<br />
相信金泰人在“客户第一 快乐生活 认真工作”的总理念指导下，一定能高标准完成客户交托的任务。向客户学习，与客户一起成长，实现大家的共同梦想。
            <div className="button11">
            <Link to="/KTTeam" >
            <Button className="button1">
              查看金泰团队</Button>
            </Link></div>
          </h2>

        </QueueAnim>

      </OverPack>
    </div>
  );
}

export default Content1;
