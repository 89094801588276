import React from 'react';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { Card, Row, Col,Popover,Button} from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';

import image1 from '../Images/art/1.png';
import image2 from '../Images/art/2.png';
import image3 from '../Images/art/3.png';
import image4 from '../Images/art/4.png';
import image5 from '../Images/art/5.jpg';
import { Link } from 'react-router-dom';
function Content1(props) {
  const { ...tagProps } = props;
  const { dataSource, isMobile } = tagProps;
  delete tagProps.dataSource;
  delete tagProps.isMobile;
  const animType = {
    queue: isMobile ? 'bottom' : 'right',
    one: isMobile
      ? {
          scaleY: '+=0.3',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        }
      : {
          x: '-=30',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        },
  };
  return (
    <div {...tagProps} {...dataSource.wrapper}>
      <OverPack {...dataSource.OverPack} component={Row}>
        <TweenOne
          key="img"
          animation={animType.one}
          resetStyle
          {...dataSource.imgWrapper}
          component={Col}
          componentProps={{
            md: dataSource.imgWrapper.md,
            xs: dataSource.imgWrapper.xs,
          }}
        >
                <Card className="box1" bordered style={{ backgroundColor: '#ecebeb' }}>
                建筑业企业纸质证书
                </Card>
                <Card className="box2" bordered style={{ backgroundColor: '#ecebeb' }}>
                "中国展览馆协会展览陈列工
程设计与施工一体化资质登记"

                </Card>
                <Card className="box3" bordered style={{ backgroundColor: '#ecebeb' }}>
                软件企业证书

                </Card>
                <Card className="box4" bordered style={{ backgroundColor: '#ecebeb' }}>
                软件产品证书
                </Card>
                <Card className="box5" bordered style={{ backgroundColor: '#ecebeb' }}>
                高新技术企业证书


                </Card>
                <Card className="box6" bordered style={{ backgroundColor: '#ecebeb' }}>
                博物馆-设计、施工两个证书

                </Card>
                <Card className="box7" bordered style={{ backgroundColor: '#ecebeb' }}>
                安全生产许可证
                </Card>
                <Card className="box8" bordered style={{ backgroundColor: '#ecebeb' }}>
                中国展览馆协会
展览工程企业资质

                </Card>
                <Card className="box9" bordered style={{ backgroundColor: '#ecebeb' }}>
                AAA级信用企业

                </Card>
                <Card className="box10" bordered style={{ backgroundColor: '#ecebeb' }}>
                质量管理体系9001

                </Card>
                <Card className="box11" bordered style={{ backgroundColor: '#ecebeb' }}>
                中国多媒体工程
系统集成资质

                </Card>
                <Card className="box12" bordered style={{ backgroundColor: '#ecebeb' }}>
                环境管理体系
认证14001

                </Card>
                <Card className="box13" bordered style={{ backgroundColor: '#ecebeb' }}>
                开户许可证
                </Card>
 
                <Card className="box15" bordered style={{ backgroundColor: '#ecebeb' }}>
                </Card>
                <Card className="box16" bordered style={{ backgroundColor: '#ecebeb' }}>
                </Card>
                <Card className="box17" bordered style={{ backgroundColor: '#ecebeb' }}>
                </Card>
                <Card className="box18" bordered style={{ backgroundColor: '#ecebeb' }}>
                </Card>
        </TweenOne>
        {/* <QueueAnim
          key="text"
          type={animType.queue}
          leaveReverse
          ease={['easeOutQuad', 'easeInQuad']}
          {...dataSource.textWrapper}
          component={Col}
          componentProps={{
            md: dataSource.textWrapper.md,
            xs: dataSource.textWrapper.xs,
          }}
        >
          
          <h2 key="h1" {...dataSource.title}>

            {dataSource.title.children}
            <Link to="/tuandui">
            <Button danger className="button1">
              查看金泰团队</Button>
            </Link>
          </h2>

        </QueueAnim> */}
      </OverPack>
    </div>
  );
}

export default Content1;
