import React from 'react';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { Button,Row, Col,Tooltip} from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import image1 from '../Images/Article/1.png';
import image2 from '../Images/Article/2.png';
import image3 from '../Images/Article/3.png';
import image4 from '../Images/Article/4.png';
import image5 from '../Images/Article/5.png';
import image6 from '../Images/Article/6.png';
import imageidea from '../Images/Article/idea.png';
import image11 from '../Images/Article/xian.png';
import { Link } from 'react-router-dom';
function Content1(props) {
  const { ...tagProps } = props;
  const { dataSource, isMobile } = tagProps;
  delete tagProps.dataSource;
  delete tagProps.isMobile;
  const animType = {
    queue: isMobile ? 'bottom' : 'right',
    one: isMobile
      ? {
          scaleY: '+=0.3',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        }
      : {
          x: '-=30',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        },
  };
  return (
    <div {...tagProps} {...dataSource.wrapper}>
                
      <OverPack {...dataSource.OverPack} component={Row}>
         <QueueAnim
          key="text"
          type={animType.queue}
          leaveReverse
          ease={['easeOutQuad', 'easeInQuad']}
          {...dataSource.textWrapper}
          component={Col}
          componentProps={{
            md: dataSource.textWrapper.md,
            xs: dataSource.textWrapper.xs,
          }}
        >
          <div className="ImageIcon">
          <h2 key="h1" {...dataSource.title}>
          数字展馆引领者  创意团队专业  数字媒体创新  知识产权自主
          </h2>
          </div>
          <div key="p" {...dataSource.content}>
          专注打造智慧展馆十余年，策划设计智造百余座案例，自主研发数十项全球前沿多媒体技术，致力于新型智慧展馆。全方位立体资质背书，上千位客户共同见证，专业团队打造独树一帜的建馆方法论。
          </div>
          <Link to="/KTQualifications"><Button  className="button1" shape="round">公司资质</Button></Link>
        </QueueAnim>
        <TweenOne
          key="img"
          animation={animType.one}
          resetStyle
          {...dataSource.imgWrapper}
          component={Col}
          componentProps={{
            md: dataSource.imgWrapper.md,
            xs: dataSource.imgWrapper.xs,
          }}
        >
          <div className="span6" {...dataSource.img}>
            <img src={imageidea}  alt="img" />
          </div>
          <div className="span7" {...dataSource.img}>
            <img src={image11}  alt="img" />
          </div>
          <div className="span" {...dataSource.img}>
            <img src={image1}  alt="img" />
          </div>
          <div className="span1" {...dataSource.img}>
            <img src={image2}  alt="img" />
          </div>
          <div className="span2" {...dataSource.img}>
            <img src={image3}  alt="img" />
          </div>
          <div className="span3" {...dataSource.img}>
            <img src={image4}  alt="img" />
          </div>
          <div className="span4" {...dataSource.img}>
            <img src={image5}  alt="img" />
          </div>
          <div className="span5" {...dataSource.img}>
            <img src={image6}  alt="img" />
          </div>
        </TweenOne>

      </OverPack>
    </div>
  );
}

export default Content1;
