import React from 'react';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import BannerAnim, { Element } from 'rc-banner-anim';
import TweenOne from 'rc-tween-one';
import QueueAnim from 'rc-queue-anim';
import { getChildrenToRender } from './utils';
import 'rc-banner-anim/assets/index.css';
import image1 from '../Images/know/1.png';
import {Transfer} from 'antd'
import image66 from '../Images/know/beijing.png'
class Teams extends React.PureComponent {
  getChildrenToRender = (children) => {
    return children.map((item, i) => {
      const { titleWrapper, ...elementPros } = item;
      return (
        <Element
          {...elementPros}
          key={i.toString()}
          prefixCls={elementPros.className}
        >
          <QueueAnim
            type={['bottom', 'top']}
            delay={200}
            key="text"
            {...titleWrapper}
          >
          <img className="image1" src={image66}></img>
            <img className="image" src={titleWrapper.image}></img>
            <h1>客户眼中的金泰</h1>
            <h3>金泰人稳扎稳打、一步一个脚印，用实际行动说话，严格把控每一个环节。<br/>

不论是几十平米，还是几百、几千、几万平米的项目；不论是本地，还是跨地域项目；不论是主题展馆展示，还是博物馆展示项目，公司上到董事长，下到现场施工的每名油漆工，一律严肃认真对待，真正做到全员对项目负责，全员对客户负责。<br/>

金泰人正因这样从上到下高度负责的精神，成就了金泰一个又一个“沉甸甸”的项目，更证明了选择金泰是完全正确的。金泰人的敬业精神和匠人精神值得我们敬佩。</h3>
          </QueueAnim>
        </Element>
      );
    });
  };

  render() {
    const { ...tagProps } = this.props;
    const { dataSource, isMobile } = tagProps;
    delete tagProps.dataSource;
    delete tagProps.isMobile;
    return (
      <div {...tagProps} {...dataSource.wrapper}>
        <OverPack {...dataSource.OverPack}>
          <TweenOne
            key="wrapper"
            animation={
              isMobile
                ? {
                    scaleY: '+=0.3',
                    opacity: 0,
                    type: 'from',
                    ease: 'easeOutQuad',
                  }
                : {
                    y: '+=30',
                    opacity: 0,
                    type: 'from',
                    ease: 'easeOutQuad',
                  }
            }
            resetStyle
            component=""
          >
          
            <BannerAnim
              type="across"
              arrow={false}
              dragPlay={!!isMobile}
              {...dataSource.BannerAnim}
            >
              
              {this.getChildrenToRender(dataSource.BannerAnim.children)}
            </BannerAnim>
          </TweenOne>
        </OverPack>
      </div>
    );
  }
}

export default Teams;
