import React from 'react';
export const Feature10DataSource = {
  wrapper: { className: 'home-page-wrapper content31-wrapper' },
  OverPack: { className: 'home-page content31', playScale: 0.3 },
  imgWrapper: { className: 'content31-img1', md: 17, xs: 24 },
  img: {
    children: 'https://zos.alipayobjects.com/rmsportal/nLzbeGQLPyBJoli.png',
  },
  img1: {
    children: 'https://zos.alipayobjects.com/rmsportal/nLzbeGQLPyBJoli.png',
  },
  textWrapper: { className: 'content31-text', md: 6, xs: 24 },
  title: { className: 'content31-title', children: '联系我们' },
  content: {
    className: 'content31-content',
    children:
      '地 址：黑龙江省哈尔滨市道外区东直路177号金泰创意 服务热线：',
  },
};