import React from 'react';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import BannerAnim, { Element } from 'rc-banner-anim';
import TweenOne from 'rc-tween-one';
import QueueAnim from 'rc-queue-anim';
import { getChildrenToRender } from './utils';
import 'rc-banner-anim/assets/index.css';
import { List, Spin, Image} from 'antd';
import {Transfer} from 'antd'
import imglixinyu from "../Images/man/person1.png";
import imglixinyupress from "../Images/man/person1-1.png";
import imgsunhao from "../Images/man/person2.png";
import imgsunhaopress from "../Images/man/person2-1.png";
import imgsunwei from "../Images/man/person3.png";
import imgsunweipress from "../Images/man/person3-1.png";
import imgsunhe from "../Images/man/person4.png";
import imgsunhepress from "../Images/man/person4-1.png";
import xuxian from "../Images/man/xuxian.png"
const data = [
  {
      index:0,
      name:"李欣昱",
      job:"金泰创始人",
      introduce:"2010年10月，荣获2010年国际大学生物联网创新创业大赛暨第四届美新杯MEMS传感器应用大赛中国总决赛二等奖。<br/>2014年9月，荣获黑龙江科技大学就业创业指导专家称号。<br/>2015年，受聘为黑龙江大学学生科技文化创业园创业导师。<br/>2015年12月，受聘为哈尔滨工程大学大学生创业联盟创业导师。<br/>2016年，省十大杰出青年创业奖获得者。<br/>2016年3月，荣获“哈尔滨市大学生创新创业导师”称号。<br/>2016年11月，荣获黑龙江省首届“守望梦想，奋斗青春”大学生创业之星称号。<br/>2017年，荣获黑龙江省三八红旗手及哈尔滨市三八红旗手标兵荣誉称号。<br/>2017年3月1日，评为为省妇联十一届三次执委（扩大）成为执委常委。<br/>2017年3月6日，被评为南岗区创新创业带头人荣誉称号。<br/>2018年3月，获得“南岗区巾帼创业创新带头人”荣誉称号。<br/>2018年7月16日，被评为第五届哈尔滨市“冰城最美人物”。<br/>2018年8月，被评选为黑龙江省妇女第十二次全国代表大会代表。<br/>2018年10月，荣获“黑龙江大学就业指导专家”称号。<br/>2018年12月，被选为哈尔滨市第二十届妇联兼职副主席。<br/>2019年10月，荣获第十届“中国青年创业奖”。<br/>2019年，荣获黑龙江省创新创业好青年荣誉称号。",
      img0:imglixinyu,
      img1:imglixinyupress,
  },
  {
      index:1,
      name:"孙昊",
      job:"技术总监",
      introduce:"微软官方认证工程师，从事数字化展览行业近十年，参与主持百余场展馆数字化展项设计实施，擅长复杂数字艺术展项的系统构架。<br/>制定部门发展计划，改进项目工作流程，对部门项目整体把控。拥有系统化IT管理的丰富经验。从业期间客户IT核心设备与业务关键软/硬件正常使用时间达到90%以上，得到了客户一致好评。 <br/>注重技术积累、部门团队建设以及人员培训。领导技术团队完成若干百万级项目。",
      img0:imgsunhao,
      img1:imgsunhaopress
  },
  {
      index:2,
      name:"孙微",
      job:"商务总监",
      introduce:"专注数字化展览领域十余年，凭借丰富的施工管理经验、严谨的工作态度、出色商务谈判能力，获得业界及客户的一致好评。<br/>负责部门组织机构设置、营销制度制定；负责指导、管理、监督各项业务工作，改善工作质量和服务态度。<br/>根据公司发展战略，对市场状况、经营环境和竞争对手进行调研分析，为经营决策提供可行性研究报告；<br/>制定公司年度营销策略和品牌战略，统筹组织公司各类推广宣传资料的创意、设计和制作。<br/>对市场进行科学的预测和分析，协同技术、采购、财务等部门对新产品商业化运作提供企划方案。",
      img0:imgsunwei,
      img1:imgsunweipress
  },
  {
      index:3,
      name:"孙赫",
      job:"运营总监",
      introduce:"具有多年的公司运营管理经验，负责公司市场营销体系的战略分析、战略定位、业务架构、运营模式、竞争战略、实施规划等方面的管理工作。<br/>具备超强的执行力、市场突破力，善于运用敏锐的市场触觉，出色的逻辑分析能力，发现需求，开荒能力强。<br/>主持公司重大营销合同的谈判与签订工作。制订客户评级标准并针对不同类型客户特质，制定系统化的营销策略。对公司重要客户，总结并形成一对一的营销公关策略。指导并监督各项策略的实施成效。<br/>建立有效的协同机制，促使营销中心内部与公司各部门高效联动作业。",
      img0:imgsunhe,
      img1:imgsunhepress
  },
];
class Teams extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
        key1:11,
        loading: false,
        hasMore: true,
        name:data[3].name,
        job:data[3].job,
        introduce:data[3].introduce
      };
 
    // 这边绑定是必要的，这样 `this` 才能在回调函数中使用
    this.onImgClick = this.onImgClick.bind(this);
}
componentDidMount() {
    document.getElementById(3).src = data[3].img0;
    // console.log(this.props.location.state.name);
}
// componentWillMount(){
//   //console.log(this.props.location)//传递过来的所有参数
//   console.log(this.props.state.key1)//val值
// }

onImgClick(e) {
    e.target.src = data[e.target.id].img0
    for (let i = 0; i < 4; i++) {
        if (i == (Number)(e.target.id)) {
            e.target.sc = data[e.target.id].img0
        } else {
            document.getElementById(i).src = data[i].img1
        }
    }
    this.setState({
        name:data[e.target.id].name,
        job:data[e.target.id].job,
        introduce:data[e.target.id].introduce
    });
}
  render() {
    const { ...tagProps } = this.props;
    const { dataSource, isMobile } = tagProps;
    delete tagProps.dataSource;
    delete tagProps.isMobile;
    return (
      <div {...tagProps} {...dataSource.wrapper}>
        <OverPack {...dataSource.OverPack}>
          <TweenOne
            key="wrapper"
            animation={
              isMobile
                ? {
                    scaleY: '+=0.3',
                    opacity: 0,
                    type: 'from',
                    ease: 'easeOutQuad',
                  }
                : {
                    y: '+=30',
                    opacity: 0,
                    type: 'from',
                    ease: 'easeOutQuad',
                  }
            }
            resetStyle
            component=""
          >

           <div className="demo">
                <div className="demo-list-container">
                    <List
                        dataSource={data}
                        renderItem={item => (
                        <List.Item style={{borderBottomColor:'#ffffff'}}>
                            {<img id={item.index} src={item.img1} alt="img" onClick={this.onImgClick}/>}
                        </List.Item>
                        )}
                    >
                                
                        {this.state.loading && this.state.hasMore && (
                        <div className="demo-loading-container">
                            <Spin />
                        </div>
                        )}
                    </List>
                </div>

                <div className="demo1" >
                    <p>
                        <span className="name">{this.state.name}</span>  
                        <span className="job">{this.state.job}</span> 
                    </p>
                    <div>
                        <img src={xuxian} alt="img"/>
                    </div>
                    <p className="introduce" style={{
overflowY: 'scroll',
maxHeight: '100vh'
}}  dangerouslySetInnerHTML = {{ __html: this.state.introduce }}></p>
                </div>
            </div>
          </TweenOne>
        </OverPack>
      </div>
    );
  }
}

export default Teams;
