import React from 'react';
export const Feature10DataSource = {
  wrapper: { className: 'home-page-wrapper content24-wrapper' },
  OverPack: { className: 'home-page content24', playScale: 0.3 },
  imgWrapper: { className: 'content24-img1', md: 24, xs: 24 },
  img: {
    children: 'https://zos.alipayobjects.com/rmsportal/nLzbeGQLPyBJoli.png',
  },
  img1: {
    children: 'https://zos.alipayobjects.com/rmsportal/nLzbeGQLPyBJoli.png',
  },
  textWrapper: { className: 'content24-text', md: 24, xs: 24 },
  title: { className: 'content24-title', children: '客户是上帝，更是衣食父母，服务客户是银行生存的唯一理由，任何时候都要以满足客户的需求为己任；坚持服务为先，做到令人称道的服务态度、令人赞许的服务效率、令人满意的服务质量。不论是办工厂也好，办企业也好，开小卖部也好，还是办银行业也好，我们都应坚持客户第一，服务卓越，这不仅是我们的企业文化，更是我们的生存之本。'
},
  content: {
    className: 'content24-content',
    children:
      '内投球幕是针对现有图像投影机平面投影的技术不足，而提供的一种新颖的、先进的，在球形屏幕上显示图像的投影装置。通过这种新颖的球形展示方式逼真的展示自然科学现象和科学技术成果达到传播、普及科学知识的目的。',
  },
};