import React from 'react';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import BannerAnim, { Element } from 'rc-banner-anim';
import TweenOne from 'rc-tween-one';
import QueueAnim from 'rc-queue-anim';
import { getChildrenToRender } from './utils';
import 'rc-banner-anim/assets/index.css';
import image1 from '../Images/know/1.png';
import {Transfer} from 'antd'
const style={
  color:'pink',
  fontSize:'30px',
  border:'1px #666 solid',
};
const style1={
  color:'pink',
  fontSize:'30px',
  border:'1px #666 solid',
};
class Teams extends React.PureComponent {
  getChildrenToRender = (children) => {
    return children.map((item, i) => {
      const { titleWrapper, ...elementPros } = item;
      function name(e) {
        switch (e.target.id)
        {
          case "1":
            document.getElementById("bigImg").src = titleWrapper.imagebig1;
            document.getElementById("1").className="id1";
            document.getElementById("2").className="id";
            document.getElementById("3").className="id";
            break;
          case "2":
            document.getElementById("bigImg").src = titleWrapper.imagebig2;
            document.getElementById("1").className="id";
            document.getElementById("2").className="id1";
            document.getElementById("3").className="id";
            break;
          case "3":
            document.getElementById("bigImg").src = titleWrapper.imagebig3;
            document.getElementById("1").className="id";
            document.getElementById("2").className="id";
            document.getElementById("3").className="id1";
            break;
          default:
              break;
        }
      }
      return (
        <Element
          {...elementPros}
          key={i.toString()}
          prefixCls={elementPros.className}
        >
          <QueueAnim
            type={['bottom', 'top']}
            delay={200}
            key="text"
            {...titleWrapper}
          >
            <div className="image">
            <img className="id1" id="1"  onMouseDown={name} src={titleWrapper.image1}></img></div>
            <div className="image1">
            <img id="2" onMouseDown={name} src={titleWrapper.image2}></img></div>
            <div className="image2">
            <img id="3" onMouseDown={name} src={titleWrapper.image3}></img></div>
            <img id="bigImg"  className="image3" src={titleWrapper.imagebig1}></img>
            <h1>{titleWrapper.title1}</h1>
            <h2>{titleWrapper.title2}</h2>
            <h3 style={{
overflowY: 'scroll',
maxHeight: '100vh'
}}>{titleWrapper.title3}</h3>
          </QueueAnim>
        </Element>
      );
    });
  };

  render() {
    const { ...tagProps } = this.props;
    const { dataSource, isMobile } = tagProps;
    delete tagProps.dataSource;
    delete tagProps.isMobile;
    return (
      <div {...tagProps} {...dataSource.wrapper}>
        <OverPack {...dataSource.OverPack}>
          <TweenOne
            key="wrapper"
            animation={
              isMobile
                ? {
                    scaleY: '+=0.3',
                    opacity: 0,
                    type: 'from',
                    ease: 'easeOutQuad',
                  }
                : {
                    y: '+=30',
                    opacity: 0,
                    type: 'from',
                    ease: 'easeOutQuad',
                  }
            }
            resetStyle
            component=""
          >
           
            <BannerAnim
              type="across"
              arrow={false}
              dragPlay={!!isMobile}
              {...dataSource.BannerAnim}
            >
              {this.getChildrenToRender(dataSource.BannerAnim.children)}
             
            </BannerAnim>
          </TweenOne>
        </OverPack>
      </div>
    );
  }
}

export default Teams;
