import React from 'react';
export const Nav00DataSource = {
  wrapper: { className: 'header0 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header0-logo',
    children: 'https://os.alipayobjects.com/rmsportal/mlcYmsRilwraoAe.svg',
  },
  Menu: {
    className: 'header0-menu',
    children: [
      { name: 'item0', a: { children: 'Home', href: '/' } },
      { name: 'item1', a: { children: 'KT版', href: '/KTCase' } },
      { name: 'item2', a: { children: '创意工厂', href: '/KTShow' } },
      { name: 'item3', a: { children: '金泰LAB', href: '/KTLab' } },
      { name: 'item0', a: { children: '客户评价', href: '/evaluate' } },
      { name: 'item1', a: { children: '商务合作', href: '/KTCooperation' } },
      { name: 'item2', a: { children: '金泰文化', href: '/KTCulture' } },
    ],
  },
  mobileMenu: { className: 'header0-mobile-menu' },
};