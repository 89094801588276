import React from 'react';
import imageone1 from '../Images/KT/1-1.png'
import image1one2 from '../Images/KT/2-2.png'
import image1one3 from '../Images/KT/3-3.png'
import imageonebig1 from '../Images/KT/1.png'
import imageonebig2 from '../Images/KT/2.png'
import imageonebig3 from '../Images/KT/3.png'
import imagetwo1 from '../Images/YY/1-1.png'
import image1two2 from '../Images/YY/2-1.png'
import image1two3 from '../Images/YY/3-1.png'
import imagetwobig1 from '../Images/YY/1.png'
import imagetwobig2 from '../Images/YY/2.png'
import imagetwobig3 from '../Images/YY/3.png'
import imagethree1 from '../Images/QD/1-1.png'
import image1three2 from '../Images/QD/2-1.png'
import image1three3 from '../Images/QD/3-1.png'
import imagethreebig1 from '../Images/QD/1.png'
import imagethreebig2 from '../Images/QD/2.png'
import imagethreebig3 from '../Images/QD/3.png'

import imageguihua1 from '../Images/guihua/1-1.png'
import imageguihua2 from '../Images/guihua/2-1.png'
import imageguihua3 from '../Images/guihua/3-1.png'

import imageguihuabig1 from '../Images/guihua/1.png'
import imageguihuabig2 from '../Images/guihua/2.png'
import imageguihuabig3 from '../Images/guihua/3.png'

import imagefuyuan1 from '../Images/fuyuan/1-1.png'
import imagefuyuan2 from '../Images/fuyuan/2-1.png'
import imagefuyuan3 from '../Images/fuyuan/3-1.png'

import imagefuyuanbig1 from '../Images/fuyuan/1.png'
import imagefuyuanbig2 from '../Images/fuyuan/2.png'
import imagefuyuanbig3 from '../Images/fuyuan/3.png'

import imagesd1 from '../Images/sd/1-1.png'
import imagesd2 from '../Images/sd/2-1.png'
import imagesd3 from '../Images/sd/3-1.png'

import imagesdbig1 from '../Images/sd/1.png'
import imagesdbig2 from '../Images/sd/2.png'
import imagesdbig3 from '../Images/sd/3.png'

import imagebg1 from '../Images/bg/1-1.png'
import imagebg2 from '../Images/bg/2-1.png'
import imagebg3 from '../Images/bg/3-1.png'

import imagebgbig1 from '../Images/bg/1.png'
import imagebgbig2 from '../Images/bg/2.png'
import imagebgbig3 from '../Images/bg/3.png'


import imagexj1 from '../Images/xj/1-1.png'
import imagexj2 from '../Images/xj/2-1.png'
import imagexj3 from '../Images/xj/3-1.png'

import imagexjbig1 from '../Images/xj/1.png'
import imagexjbig2 from '../Images/xj/2.png'
import imagexjbig3 from '../Images/xj/3.png'

import imageshengjiwei1 from '../Images/shengjiwei/1-1.png'
import imageshengjiwei2 from '../Images/shengjiwei/2-1.png'
import imageshengjiwei3 from '../Images/shengjiwei/3-1.png'

import imageshengjiweibig1 from '../Images/shengjiwei/1.png'
import imageshengjiweibig2 from '../Images/shengjiwei/2.png'
import imageshengjiweibig3 from '../Images/shengjiwei/3.png'

import imagebingxue1 from '../Images/bingxue/1-1.png'
import imagebingxue2 from '../Images/bingxue/2-1.png'
import imagebingxue3 from '../Images/bingxue/3-1.png'

import imagebingxuebig1 from '../Images/bingxue/1.png'
import imagebingxuebig2 from '../Images/bingxue/2.png'
import imagebingxuebig3 from '../Images/bingxue/3.png'

export const Teams00DataSource = {
  wrapper: { className: 'home-page-wrapper teams01-wrapper' },
  OverPack: { playScale: 0.3, className: 'home-page teams01' },

  BannerAnim: {
    className: 'banner-anim',
    children: [
      {
        name: 'elem0',
        className: 'teams01-banner-user-elem',
        titleWrapper: {
          className: 'teams01-content-wrapper',
          image1:imageone1,
          image2:image1one2,
          image3:image1one3,
          imagebig1:imageonebig1,
          imagebig2:imageonebig2,
          imagebig3:imageonebig3,
          title1:'黑龙江学分银行',
          title2:'全国首家、线上线下一体、教育服务中心',
          title3:'2019年10月31日，由黑龙江省教育厅主办，黑龙江广播电视大学承办，哈尔滨金泰科技开发有限公司协办的“黑龙江省学分银行公共服务平台项目”（一期）正式启动。此项目坐落于美丽的冰城哈尔滨，历时八个月完成实体中心的建设工作。此项目致力于打造“学分银行”公共服务平台实体运营中心，使其成为横向衔接各类教育形式，纵向沟通院校企业个人为一体的教育认证机构。',
        },
        
      },
      {
        name: 'elem0',
        className: 'teams01-banner-user-elem',
        titleWrapper: {
          className: 'teams01-content-wrapper',
          image1:imagetwo1,
          image2:image1two2,
          image3:image1two3,
          imagebig1:imagetwobig1,
          imagebig2:imagetwobig2,
          imagebig3:imagetwobig3,
          title1:'哈尔滨音乐博物馆',
          title2:'音乐之城、音乐文化交流地、音乐启蒙的发源地',
          title3:'哈尔滨音乐博物馆坐落在美丽的松花江畔，与哈尔滨大剧院和哈尔滨职工艺术中心相连接。在设计过程中，主要体现音乐博物馆与比邻的两座音乐建筑、美丽的松花江水浑然天成 。博物馆建筑面积4100平方米，博物馆高度3米，设计保留玻璃幕墙设计，引用自然光与博物馆结合设计理念。目前收集整理的音乐实物约400件。',
        },
        
      },      {
        name: 'elem0',
        className: 'teams01-banner-user-elem',
        titleWrapper: {
          className: 'teams01-content-wrapper',
          image1:imagethree1,
          image2:image1three2,
          image3:image1three3,
          imagebig1:imagethreebig1,
          imagebig2:imagethreebig2,
          imagebig3:imagethreebig3,
          title1:'崂山金融素质教育基地',
          title2:'全球首家、航空&财商教育',
          title3:'青岛金融航空素质教育基地，坐落于青岛市崂山区苗岭路10号（崂山区城市发展展示中心），内设有航空飞行体验区、金融区两大主区域，可同时接纳800余人参观体验。航空飞行体验区共设有11台飞行模拟机，均经过波音公司授权，按照波音737-800型号1：1比例研发和制造。可同时满足专业飞行员以及飞行爱好者的双重需求，为参观者带来视觉与感官的双重震撼。金融区域包含：金融科普、金融迷宫、金融超市、金融互动等多个体验项目，并设置家长陪伴体验区域。为参观者量身打造温馨陪伴、智掌未来的沉浸式互动体验。此项目于2019年6月正式交付使用，得到了社会各界人士的关注与首肯。青岛市市长孟凡利对项目的落成表示祝贺，并充分肯定了项目在数字AI技术科研创新和实际应用领域取得的先进成果。该基地集文创、教育、科技三大领域，成为全球首家儿童财商素质教育基地，为青岛市再树科技创新新标杆。',
        },
        
      },
      {
        name: 'elem0',
        className: 'teams01-banner-user-elem',
        titleWrapper: {
          className: 'teams01-content-wrapper',
          image1:imageguihua1,
          image2:imageguihua2,
          image3:imageguihua3,
          imagebig1:imageguihuabig1,
          imagebig2:imageguihuabig2,
          imagebig3:imageguihuabig3,
          title1:'哈尔滨规划展览馆',
          title2:'',
          title3:'哈尔滨规划展览馆是哈尔滨市政府城建十大重点工程之一，占地面积8800平方米，总建筑面积21285平方米，地上4层，地下1层。展区分三层：以“留下历史印记，引领城市未来”为主题，一层为城市历史展区，二层为城市规划展区，三层为区县展区和互动区，中间三层天井式共享空间布置了1600平方米哈尔滨市总体规划物理沙盘。展区共包含印象哈尔滨、城市溯源、总体规划、靓点哈尔滨、八区十县（市）、新型城镇化等30个分展区。按照历史、现在和未来的脉络，通过各种高科技手段生动形象地展示了城市的发展历程和未来规划蓝图。',
        },
        
      },
      {
        name: 'elem0',
        className: 'teams01-banner-user-elem',
        titleWrapper: {
          className: 'teams01-content-wrapper',
          image1:imagefuyuan1,
          image2:imagefuyuan2,
          image3:imagefuyuan3,
          imagebig1:imagefuyuanbig1,
          imagebig2:imagefuyuanbig2,
          imagebig3:imagefuyuanbig3,
          title1:'抚远边境党建馆',
          title2:'音乐之城、音乐文化交流地、音乐启蒙的发源地',
          title3:'抚远边境党建馆作为全国首家边境党建馆，坐落于华夏东极黑龙江省抚远市。项目历时4个月，于2019年7月正式落成。整体恢弘大气，局部协调和谐，代表着抚远党建的整体形象。艺术价值与功能价值兼备，时代感与行业特色融为一体，象征抚远党建与时俱进，不忘初心，牢记使命。灵活的艺术思维和凝练的技术手段，充分结合，1·预示抚远党建事业的蓬勃发展。',
        },
        
      },
      {
        name: 'elem0',
        className: 'teams01-banner-user-elem',
        titleWrapper: {
          className: 'teams01-content-wrapper',
          image1:imagesd1,
          image2:imagesd2,
          image3:imagesd3,
          imagebig1:imagesdbig1,
          imagebig2:imagesdbig2,
          imagebig3:imagesdbig3,
          title1:'哈尔滨师范大学校史馆',
          title2:'艺术创作、馆中馆结合',
          title3:'哈尔滨师范大学校史馆于2021年正式建成。采用传统展览方式与多媒体手段相结合的展示形式，秉承叙述符合逻辑、参观路线合理、空间利用最大化、展示亮点突出、内容方便更新的设计原则，兼顾精细参观与浏览式参观。以丰富的辅助展示手段，倾力展现哈师大“行知精神”，让参观者真切地感受到哈尔滨师范大学在历史发展中精神文化的传承与创新。',
        },
        
      },
      {
        name: 'elem0',
        className: 'teams01-banner-user-elem',
        titleWrapper: {
          className: 'teams01-content-wrapper',
          image1:imagebg1,
          image2:imagebg2,
          image3:imagebg3,
          imagebig1:imagebgbig1,
          imagebig2:imagebgbig2,
          imagebig3:imagebgbig3,
          title1:'包钢精神主题展厅',
          title2:'',
          title3:'该展厅包钢精神在传承为主题，体现出包钢精神是最宝贵的精神财富和不断发展的不竭动力，在新时期引领包钢人不断迈向新的里程碑，该展厅以红色基因为故事线，分为六大展示区域，同时引入了魔幻玻璃、3D定向投影技术、场景式局部投影技术讲历史新讲，并对包钢的未来进行了美好憧憬。该展厅通过与厂区沙盘展示厅、厂史馆、稀土厅、廉政教育展示厅五大展厅相结合，形成包钢特色党性教育基地，也影响着一代代的包钢人。',
        },
        
      },
      {
        name: 'elem0',
        className: 'teams01-banner-user-elem',
        titleWrapper: {
          className: 'teams01-content-wrapper',
          image1:imagexj1,
          image2:imagexj2,
          image3:imagexj3,
          imagebig1:imagexjbig1,
          imagebig2:imagexjbig2,
          imagebig3:imagexjbig3,
          title1:'可可托海国家地质公园',
          title2:'',
          title3:'阿依果孜矿洞长约800米，洞内寒气逼人。洞内有许多错综、交叉、相通的通道，有如地下迷宫一般充满着神秘色彩。巷道岩壁上清晰可见凿岩痕迹和层次分明的矿脉。矿洞观光体验，不仅是一次亲近矿洞、深入地层、认识矿物、体验采矿的过程，更能近距离震撼感受老一辈矿工凿岩挖宝的艰辛与不易。针对矿洞内的特殊环境，为了增加智慧旅游的可观赏性，让游客更加了解当时的历史故事，采用3Dmapping技术及纱幕成像技术，将历史故事还原，同时洞内的温度较低，也针对此特殊情况进行技术处理，保证的展示效果。2017年5月，联合国教科文组织批准新疆可可托海国家地质公园列入世界地质公园网络名录。',
        },
        
      },
      {
        name: 'elem0',
        className: 'teams01-banner-user-elem',
        titleWrapper: {
          className: 'teams01-content-wrapper',
          image1:imageshengjiwei1,
          image2:imageshengjiwei2,
          image3:imageshengjiwei3,
          imagebig1:imageshengjiweibig1,
          imagebig2:imageshengjiweibig2,
          imagebig3:imageshengjiweibig3,
          title1:'智慧纪检监察大数据中心',
          title2:'智慧大数据、黑龙江试点单位',
          title3:'2019年初，哈尔滨金泰科技开发有限公司携手黑龙江省纪委监委共同打造完成“智慧纪检监察大数据中心”项目。展厅位于哈尔滨市南岗区中山路153号省纪委监委新建办公楼二楼，展示面积400余平方米，从整体设计、展示系统、建设完成历时四个月。展厅在2019年元月预展期间已有数十名省委领导莅临指导，同时接待全省各地市纪检领导百余人，参观后领导们均给予充分认可及高度评价。',
        },
        
      },

    ],
  },
};
