import React from 'react';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { Row, Col,Popover,Button} from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import image1 from '../Images/liucheng/1.png';
import image2 from '../Images/liucheng/2.png';
import image3 from '../Images/liucheng/3.png';
import image4 from '../Images/liucheng/4.png';
import image5 from '../Images/liucheng/xian.png';
import { Link } from 'react-router-dom';
function Content1(props) {
  const { ...tagProps } = props;
  const { dataSource, isMobile } = tagProps;
  delete tagProps.dataSource;
  delete tagProps.isMobile;
  const animType = {
    queue: isMobile ? 'bottom' : 'right',
    one: isMobile
      ? {
          scaleY: '+=0.3',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        }
      : {
          x: '-=30',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        },
  };
  return (
    <div {...tagProps} {...dataSource.wrapper}>
      <OverPack {...dataSource.OverPack} component={Row}>
        <TweenOne
          key="img"
          animation={animType.one}
          resetStyle
          {...dataSource.imgWrapper}
          component={Col}
          componentProps={{
            md: dataSource.imgWrapper.md,
            xs: dataSource.imgWrapper.xs,
          }}
        >
         <div className="span4" {...dataSource.img}>
              <img src={image5} id='img_1' alt="img" />
          </div>
          <div className="span" {...dataSource.img}>
              <img src={image1} id='img_1' alt="img" />
          </div>
          <div className="span1" {...dataSource.img}>
              <img src={image2} id='img_1' alt="img" />
          </div>
          <div className="span2" {...dataSource.img}>
              <img src={image3} id='img_1' alt="img" />
          </div>
          <div className="span3" {...dataSource.img}>
              <img src={image4} id='img_1' alt="img" />
          </div>
        </TweenOne>
        <QueueAnim
          key="text"
          type={animType.queue}
          leaveReverse
          ease={['easeOutQuad', 'easeInQuad']}
          {...dataSource.textWrapper}
          component={Col}
          componentProps={{
            md: dataSource.textWrapper.md,
            xs: dataSource.textWrapper.xs,
          }}
        >
          <div className="imageicon">
          <h2  {...dataSource.title}>
            "  结合展馆布局为展馆量身定制解决方案：
空间设计+创意文化+工程执行=解决方案
                            "
          </h2>
          <h2 {...dataSource.title1}>
            "  
                                根据客户需求量身定制多功能展示程序：
用户体验+文案创意+程序研发=私人定制
                            
                            "
          </h2>
          <h2  {...dataSource.title2}>
            "  
                                庞大的工程施工团队为您制定施工执行方案
控制施工风险，施工进度、设备安装调试：
展馆申报材料提交
                            
                            "
          </h2>
          <h2  {...dataSource.title3}>
            "  
                                对签收合格的项目制定维护计划书以及郑重承诺：
终身质量跟踪服务，以及程序升级：
向用户奉献完备的技术和优质的维护服务！
                            
                            "
          </h2>
          </div>
          <Link to="/KTContact"><Button  className="button111" shape="round">联系方式</Button></Link>
        </QueueAnim>
      </OverPack>
    </div>
  );
}

export default Content1;
