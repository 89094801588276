import React from 'react';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { Row, Col,Popover} from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';

import image1 from '../Images/LAB/anniu1_hui.png';
import image2 from '../Images/LAB/anniu2_hui.png';
import image3 from '../Images/LAB/anniu3_hui.png';
import image4 from '../Images/LAB/anniu4_hui.png';
import image5 from '../Images/LAB/anniu5_hui.png';

import image11 from '../Images/LAB/anniu1.png';
import image22 from '../Images/LAB/anniu2.png';
import image33 from '../Images/LAB/anniu3.png';
import image44 from '../Images/LAB/anniu4.png';
import image55 from '../Images/LAB/anniu5.png';
import image554 from '../Images/LAB/img.png';
import imagepath from '../Images/LAB/The path.png'
import imgLast from '../Images/LAB/icon.png'
function Content1(props) {
  const { ...tagProps } = props;
  const { dataSource, isMobile } = tagProps;
  delete tagProps.dataSource;
  delete tagProps.isMobile;
  const animType = {
    queue: isMobile ? 'bottom' : 'right',
    one: isMobile
      ? {
          scaleY: '+=0.3',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        }
      : {
          x: '-=30',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        },
  };

  const content1 = (
    <div>
      <p>Content</p>
      <div align='center'>
        <button>播放视频</button>
      </div>
    </div>
  );
  const content2 = (
    <div>
      <p>Content</p>
      <button>播放视频</button>
    </div>
  );
  const content3 = (
    <div>
      <p>Content</p>
      <button>播放视频</button>
    </div>
  );
  const content4 = (
    <div>
      <p>Content</p>
      <button>播放视频</button>
    </div>
  );
  const content5 = (
    <div>
      <p>Content</p>
      <button>播放视频</button>
    </div>
  );
  const content6 = (
    <div>
      <p>Content</p>
      <button>播放视频</button>
    </div>
  );

  function imageOnMouseEnter (e) {
    switch (e.target.id)
    {
      case "img_1":
        e.target.src = image11
        break;
      case "img_2":
        e.target.src = image22
        break;
      case "img_3":
        e.target.src = image33
        break;
      case "img_4":
        e.target.src = image44
        break;
      case "img_5":
        e.target.src = image55
        break;
      default:
          break;
    }
  };

  function imageOnMouseLeave (e) {
    switch (e.target.id)
    {
      case "img_1":
        e.target.src = image1
        break;
      case "img_2":
        e.target.src = image2
        break;
      case "img_3":
        e.target.src = image3
        break;
      case "img_4":
        e.target.src = image4
        break;
      case "img_5":
        e.target.src = image5
        break;
      default:
          break;
    }
  };

  return (
    <div {...tagProps} {...dataSource.wrapper}>
      <OverPack {...dataSource.OverPack} component={Row}>
        <TweenOne
          key="img"
          animation={animType.one}
          resetStyle
          {...dataSource.imgWrapper}
          component={Col}
          componentProps={{
            md: dataSource.imgWrapper.md,
            xs: dataSource.imgWrapper.xs,
          }}
        >
        
          <div className="span5" {...dataSource.img}>
              <img src={imagepath} id='img_1' alt="img" />
          </div>
          <div className="span" {...dataSource.img}>
            <Popover placement="top" title="请扫码关注抖音账号" content={<div><img style={{position:"relative",display:"flex", justifyContent:"center",textAlign:"center" }} src={image554} alt=""/></div>}>
              <img src={image1} id='img_1' alt="img" onMouseEnter={imageOnMouseEnter} onMouseLeave={imageOnMouseLeave}/>
            </Popover>
          </div>
          <div className="span1" {...dataSource.img1}>
            <Popover placement="top" title="请扫码关注抖音账号" content={<div><img style={{position:"relative",display:"flex", justifyContent:"center",textAlign:"center" }} src={image554} alt=""/></div>}>
              <img src={image4} id='img_4' alt="img" onMouseEnter={imageOnMouseEnter} onMouseLeave={imageOnMouseLeave}/>
            </Popover>
          </div>
          <div className="span2" {...dataSource.img}>
            <Popover placement="top" title="请扫码关注抖音账号" content={<div><img style={{position:"relative",display:"flex", justifyContent:"center",textAlign:"center" }} src={image554} alt=""/></div>}>
              <img src={image2} id='img_2' alt="img" onMouseEnter={imageOnMouseEnter} onMouseLeave={imageOnMouseLeave}/>
            </Popover>
          </div>
          <div className="span3" {...dataSource.img}>
            <Popover placement="top" title="请扫码关注抖音账号" content={<div><img style={{position:"relative",display:"flex", justifyContent:"center",textAlign:"center" }} src={image554} alt=""/></div>}>
              <img src={image3} id='img_3' alt="img" onMouseEnter={imageOnMouseEnter} onMouseLeave={imageOnMouseLeave}/>
            </Popover>
          </div>
          <div className="span4" {...dataSource.img}>
            <Popover placement="top" title="请扫码关注抖音账号" content={<div><img style={{position:"relative",display:"flex", justifyContent:"center",textAlign:"center" }} src={image554} alt=""/></div>}>
              <img src={image5} id='img_5' alt="img" onMouseEnter={imageOnMouseEnter} onMouseLeave={imageOnMouseLeave}/>
            </Popover>
          </div>

        </TweenOne>
        <QueueAnim
          key="text"
          type={animType.queue}
          leaveReverse
          ease={['easeOutQuad', 'easeInQuad']}
          {...dataSource.textWrapper}
          component={Col}
          componentProps={{
            md: dataSource.textWrapper.md,
            xs: dataSource.textWrapper.xs,
          }}
        >
          
          <h2 key="h1" {...dataSource.title}>
            <img className="ImageIcon" src={imgLast} ></img>
            {dataSource.title.children}
          </h2>
          <div  key="p" {...dataSource.content}>
        金泰LAB为金泰创意主阵地，这里集合了金泰软件研发师、空间设计师、平面设计师、UI交互设计师、内容策划师们源源不断的创意灵感。<br/> 在这里，无数“创意天才”们脑洞大开，提供各种奇思妙想，同时，经过反复研讨、测试，站在实战角度，考量并实际优化创意点，综合分析，给出项目最佳“创意方案”。同时，核心创意团队更加关注客户主诉求，全程跟进“创意方案”的落地执行，用“创意”帮助客户完成梦想。

          </div>
        </QueueAnim>
      </OverPack>
    </div>
  );
}

export default Content1;
