import React from 'react';
export const Teams00DataSource = {
  wrapper: { className: 'home-page-wrapper teams03-wrapper' },
  OverPack: { playScale: 0.3, className: 'home-page teams03' },

  BannerAnim: {
    className: 'banner-anim',
    children: [
      {
        name: 'elem0',
        
      },
    ],
  },
};
