import React from 'react';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import {Button, Row, Col,Popover} from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { Link } from 'react-router-dom';
import image1 from '../Images/hezuo/1.jpg';
import imgLast from '../Images/hezuo/2.png'
function Content1(props) {
  const { ...tagProps } = props;
  const { dataSource, isMobile } = tagProps;
  delete tagProps.dataSource;
  delete tagProps.isMobile;
  const animType = {
    queue: isMobile ? 'bottom' : 'right',
    one: isMobile
      ? {
          scaleY: '+=0.3',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        }
      : {
          x: '-=30',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        },
  };

 

  return (
    <div {...tagProps} {...dataSource.wrapper}>
      <OverPack {...dataSource.OverPack} component={Row}>
        <TweenOne
          key="img"
          animation={animType.one}
          resetStyle
          {...dataSource.imgWrapper}
          component={Col}
          componentProps={{
            md: dataSource.imgWrapper.md,
            xs: dataSource.imgWrapper.xs,
          }}
        >
          <span {...dataSource.img}>
              <img src={image1} id='img_1' alt="img" />
          </span>
        </TweenOne>
        <QueueAnim
          key="text"
          type={animType.queue}
          leaveReverse
          ease={['easeOutQuad', 'easeInQuad']}
          {...dataSource.textWrapper}
          component={Col}
          componentProps={{
            md: dataSource.textWrapper.md,
            xs: dataSource.textWrapper.xs,
          }}
        >
          
          <h2 key="h1" {...dataSource.title}>
            <img className="ImageIcon" src={imgLast} ></img>
            {dataSource.title.children}
          </h2>
          <div key="p" {...dataSource.content}>
          金泰科技成立至今已走过十一载春秋，现已在全国组建五大创意基地，服务上百个项目，自主研发专利百余项，在全国二十余省落地生根。本着贴心服务的理念，金泰每执行一个项目，都要严格经过十大执行部门，三十余名项目成员，近四千个工作环节，上万个小时的精心打磨，力求精益求精，用一个又一个精品工程来回报客户对我们的信任。
          </div>
          <div className="button1">
          <Link to="/KTProcess"><Button  shape="round">合作流程</Button></Link>
          <Link to="/KTContact"><Button  shape="round">联系方式</Button></Link>
          </div>
        </QueueAnim>
      </OverPack>
    </div>
  );
}

export default Content1;
