import React, { Component } from 'react';
import { HashRouter as Router, Route,withRouter } from "react-router-dom";
import { enquireScreen } from 'enquire-js';
import Header from './Home/Nav0';
// import Footer from './Home/Footer0';
import Home from './Home';
import Page from './Page';
import Hezuo from './Hezuo';
import app from './App.css'
import wenhua from './wenhua';
import pingjia from './pingjia';
import tuandui from './People';
import anli from './anli'
import liucheng from './liucheng'
import lianxi from './lianxi'
import zizhi from './zizhi'
import tuandui1 from './tuandui'
import tuandui2 from './tuandui1'
import tuandui3 from './tuandui2'
import tuandui4 from './tuandui3'
import Lab from './LAB'
import {
  Nav00DataSource,
  Footer00DataSource,
} from './Home/data.source.js';
const style ={
  bgd:{
    color:"black"
  }
}
let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
    };
  }
  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
  }
  render() {
    return (
      <Router>
        <div className="aaa">
          <Header dataSource={Nav00DataSource} isMobile={this.state.isMobile}  />
          <Route exact path="/" component={Home} />
          <Route path="/KTShow" component={Page} />
          <Route path="/KTCooperation" component={Hezuo} />
          <Route path="/KTCulture" component={wenhua} />
          <Route path="/evaluate" component={pingjia} />
          <Route path="/KTTeam" component={tuandui} />
          <Route path="/KTCase" component={anli} />
          <Route path="/KTProcess" component={liucheng} />
          <Route path="/KTContact" component={lianxi} />
          <Route path="/KTQualifications" component={zizhi} />
          <Route path="/lixinyu/" component={tuandui1} />
          <Route path="/sunhao/" component={tuandui2} />
          <Route path="/sunwei/" component={tuandui3} />
          <Route path="/sunhe/" component={tuandui4} />
          <Route path="/KTLab" component={Lab} />
          {/* <Footer dataSource={Footer00DataSource} isMobile={this.state.isMobile} /> */}
        </div>
      </Router>
    );
  }
}

export default App;
