import React from 'react';
export const Feature10DataSource = {
  wrapper: { className: 'home-page-wrapper content11-wrapper' },
  OverPack: { className: 'home-page content11', playScale: 0.3 },
  imgWrapper: { className: 'content11-img1', md: 17, xs: 24 },
  img: {
    children: 'https://zos.alipayobjects.com/rmsportal/nLzbeGQLPyBJoli.png',
  },
  img1: {
    children: 'https://zos.alipayobjects.com/rmsportal/nLzbeGQLPyBJoli.png',
  },
  textWrapper: { className: 'content11-text', md: 6, xs: 24 },
  title: { className: 'content11-title', children: '商务合作预约' },
  content: {
    className: 'content11-content',
    children:
      '内投球幕是针对现有图像投影机平面投影的技术不足，而提供的一种新颖的、先进的，在球形屏幕上显示图像的投影装置。通过这种新颖的球形展示方式逼真的展示自然科学现象和科学技术成果达到传播、普及科学知识的目的。',
  },
};