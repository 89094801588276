import React from 'react';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import { Row, Col,Popover,Button} from 'antd';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';

import image1 from '../Images/Team/1.png';
import image2 from '../Images/Team/2.png';
import image3 from '../Images/Team/3.png';
import image4 from '../Images/Team/4.png';
import image5 from '../Images/Team/beijing.jpg';

import image11 from '../Images/team1/1.png';
import image22 from '../Images/team1/2.png';
import image33 from '../Images/team1/3.png';
import image44 from '../Images/team1/4.png';

import imagepath from '../Images/LAB/The path.png'
import imgLast from '../Images/Team/icon.png'
import { Link } from 'react-router-dom';
function Content1(props) {
  const { ...tagProps } = props;
  const { dataSource, isMobile } = tagProps;
  delete tagProps.dataSource;
  delete tagProps.isMobile;
  const animType = {
    queue: isMobile ? 'bottom' : 'right',
    one: isMobile
      ? {
          scaleY: '+=0.3',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        }
      : {
          x: '-=30',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        },
  };
  const colors = [
    'pink'
  ];
  const content1 = (
    <div>
      <h1 className="titlemin">Content</h1>
      <h2 className="zhengwen">ContentContentContentContent
      ContentContentContentContent</h2>
      <div align='center'>
        <Button>播放视频</Button>
      </div>
    </div>
  );
  const content2 = (
    <div>
      <p>Content</p>
      <Button>播放视频</Button>
    </div>
  );
  const content3 = (
    <div>
      <img>src={image1}</img>
      <p>Content</p>
      <Button>播放视频</Button>
    </div>
  );
  const content4 = (
    <div>
      <img>src={image1}</img>
      <p>Content</p>
      <button>播放视频</button>
    </div>
  );
  const content5 = (
    <div>
      <p>Content</p>
      <button>播放视频</button>
    </div>
  );
  const content6 = (
    <div>
      <img>src={image1}</img>
      <p>Content</p>
      <button>播放视频</button>
    </div>
  );

  function imageOnMouseEnter (e) {
    switch (e.target.id)
    {
      case "img_1":
        e.target.src = image11
        break;
      case "img_2":
        e.target.src = image22
        break;
      case "img_3":
        e.target.src = image33
        break;
      case "img_4":
        e.target.src = image44
        break;
      case "img_5":
        break;
      default:
          break;
    }
  };

  function imageOnMouseLeave (e) {
    switch (e.target.id)
    {
      case "img_1":
        e.target.src = image1
        break;
      case "img_2":
        e.target.src = image2
        break;
      case "img_3":
        e.target.src = image3
        break;
      case "img_4":
        e.target.src = image4
        break;
      case "img_5":
        e.target.src = image5
        break;
      default:
          break;
    }
  };

  return (
    <div {...tagProps} {...dataSource.wrapper}>
      <OverPack {...dataSource.OverPack} component={Row}>
      <QueueAnim
          key="text"
          type={animType.queue}
          leaveReverse
          ease={['easeOutQuad', 'easeInQuad']}
          {...dataSource.textWrapper}
          component={Col}
          componentProps={{
            md: dataSource.textWrapper.md,
            xs: dataSource.textWrapper.xs,
          }}
        >
          
          <h2 key="h1" {...dataSource.title}>
            <img className="ImageIcon" src={imgLast} ></img>
            {dataSource.title.children}
          </h2>
        </QueueAnim>
        <TweenOne
          key="img"
          animation={animType.one}
          resetStyle
          {...dataSource.imgWrapper}
          component={Col}
          componentProps={{
            md: dataSource.imgWrapper.md,
            xs: dataSource.imgWrapper.xs,
          }}
        >
          <div>
          <div className="span5" {...dataSource.img}>
            <img src={image5}  alt="img" />
          </div>
          <div className="span" {...dataSource.img}>
            <Popover title='Tooltip' placement="top" title="李欣昱" content={<div><img style={{position:"absolute", width: 59, height: 59 }} src={image11} alt="" /><p style={{position:"relative", top:-5, left:70, width:100, height:30,fontSize:13,marginRight:30}} >董事长-创始人</p><p style={{position:"relative",top:-20, left:70, width: 80, height: 20,fontSize:10,marginRight:30}} >金泰品牌创始人
数字互动艺术专家
                            </p><Link to="lixinyu"><Button style={{position:"relative",left:60,top:20,marginBottom:20,fontSize:10}} shape="round">了解详情</Button></Link></div>}>
              <img src={image1} id='img_1' alt="img"/>
            </Popover>
          </div>
          <div className="span1" {...dataSource.img1}>
          <Popover title='Tooltip' placement="top" title="孙赫" content={<div><img style={{position:"absolute", width: 59, height: 59 }} src={image44} alt="" /><p style={{position:"relative", top:-5, left:70, width:100, height:30,fontSize:13,marginRight:30}} ></p><p style={{position:"relative",top:-20, left:70, width: 80, height: 20,fontSize:10,marginRight:30}} >运营总监
                            </p><Link to="/sunhe"><Button style={{position:"relative",left:60,top:20,marginBottom:20,fontSize:10}} shape="round">了解详情</Button></Link></div>}>
              <img src={image4} id='img_1' alt="img"/>
            </Popover>
          </div>
          <div className="span2" {...dataSource.img}>
          <Popover title='Tooltip' placement="top" title="孙昊" content={<div><img style={{position:"absolute", width: 59, height: 59 }} src={image22} alt="" /><p style={{position:"relative", top:-5, left:70, width:100, height:30,fontSize:13,marginRight:30}} ></p><p style={{position:"relative",top:-20, left:70, width: 80, height: 20,fontSize:10,marginRight:30}} >技术总监
                            </p><Link to="/sunhao"><Button style={{position:"relative",left:60,top:20,marginBottom:20,fontSize:10}} shape="round">了解详情</Button></Link></div>}>
              <img src={image2} id='img_1' alt="img"/>
            </Popover>
          </div>
          <div className="span3" {...dataSource.img}>
          <Popover title='Tooltip' placement="top" title="孙微" content={<div><img style={{position:"absolute", width: 59, height: 59 }} src={image33} alt="" /><p style={{position:"relative", top:-5, left:70, width:100, height:30,fontSize:13,marginRight:30}} ></p><p style={{position:"relative",top:-20, left:70, width: 80, height: 20,fontSize:10,marginRight:30}} >商务总监
                            </p><Link to="/sunwei"><Button style={{position:"relative",left:60,top:20,marginBottom:20,fontSize:10}} shape="round">了解详情</Button></Link></div>}>
              <img src={image3} id='img_1' alt="img"/>
            </Popover>
          </div>
          </div>
        </TweenOne>
       
      </OverPack>
    </div>
  );
}

export default Content1;
