import React from 'react';
import imageone1 from '../Images/KT/1-1.png'
import image1one2 from '../Images/KT/2-2.png'
import image1one3 from '../Images/KT/3-3.png'
import imageonepress1 from '../Images/KT/1-2.png'
import imageonepress2 from '../Images/KT/2-3.png'
import imageonepress3 from '../Images/KT/3-4.png'
import imageonebig1 from '../Images/KT/1.png'
import imageonebig2 from '../Images/KT/2.png'
import imageonebig3 from '../Images/KT/3.png'
import imagetwo1 from '../Images/YY/1-1.png'
import image1two2 from '../Images/YY/2-1.png'
import image1two3 from '../Images/YY/3-1.png'
import imagetwopress1 from '../Images/YY/1-2.png'
import imagetwopress2 from '../Images/YY/2-2.png'
import imagetwopress3 from '../Images/YY/3-2.png'
import imagetwobig1 from '../Images/YY/1.png'
import imagetwobig2 from '../Images/YY/2.png'
import imagetwobig3 from '../Images/YY/3.png'
import imagethree1 from '../Images/QD/1-1.png'
import image1three2 from '../Images/QD/2-1.png'
import image1three3 from '../Images/QD/3-1.png'
import imagethreepress1 from '../Images/QD/1-2.png'
import imagethreepress2 from '../Images/QD/2-2.png'
import imagethreepress3 from '../Images/QD/3-2.png'
import imagethreebig1 from '../Images/QD/1.png'
import imagethreebig2 from '../Images/QD/2.png'
import imagethreebig3 from '../Images/QD/3.png'
export const Teams00DataSource = {
  wrapper: { className: 'home-page-wrapper teams02-wrapper' },
  OverPack: { playScale: 0.3, className: 'home-page teams02' },

  BannerAnim: {
    className: 'banner-anim',
    children: [
      {
        name: 'elem0',
        className: 'teams02-banner-user-elem',
        titleWrapper: {
          className: 'teams02-content-wrapper',
          image1:imageone1,
          image2:image1one2,
          image3:image1one3,
          imagebig1:imageonebig1,
          imagebig2:imageonebig2,
          imagebig3:imageonebig3,
          imagepress1:imageonepress1,
          imagepress2:imageonepress2,
          imagepress3:imageonepress3,
          title1:'科普博物馆设计',
          title2:'(项目时间：2019-03-01—2019-06-30)',
          title3:'内投球幕是针对现有图像投影机平面投影的技术不足，而提供的一种新颖的、先进的，在球形屏幕上显示图像的投影装置。通过这种新颖的球形展示方式逼真的展示自然科学现象和科学技术成果达到传播、普及科学知识的目的。 我们将综合利用艺术化、智能化、现代化的手段对广西科技馆这个科技知识教育的窗口进行深层次、全方位、多角度、立体化的集中展示，为建设创新型广西和构建和谐广西服务。',
        },
        
      },
    ],
  },
};
